import { RevenuesApi, UserManagementApi } from './client-axios/api';
import axios from 'axios';
import {
  AdminApi,
  AdminSettingApi,
  AssetsApi,
  AuthApi,
  ChatSupportGroupApi,
  ChatSupportMessageApi,
  Configuration,
  ItemInquiryMessageApi,
  ItemInternalMessageApi,
  ItemMessageGroupApi,
  PaymentSettingsApi,
  PermissionsApi,
  PlansApi,
  RacksApi,
  ShipToBuyerApi,
  StorageItemApi,
  StorageItemNotesApi,
  StorageProcessApi,
  StorageRequestApi,
  SystemNotificationApi,
  UsersApi,
  ShippoApiApi,
  EbayReturnManagerApi,
  EbayManagerApi,
  ExchangeRateApi,
  FileExportApi,
  CouponApi,
} from './client-axios';
import { CustomHandleError } from '../components/response/error';

const config = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  accessToken: localStorage.getItem('token') || undefined,
});
export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  const tokenFromLocalStorage = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${tokenFromLocalStorage}`;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/signin';
    }
    CustomHandleError(error?.response?.data);
    return Promise.reject(error);
  },
);

const authApi = new AuthApi(config, undefined, axiosInstance);
// const customerApi = new CustomersApi(config, undefined, axiosInstance);
// const roleApi = new RolesApi(config, undefined, axiosInstance);
const permissionApi = new PermissionsApi(config, undefined, axiosInstance);
const systemNotificationApi = new SystemNotificationApi(config, undefined, axiosInstance);
const usersApi = new UsersApi(config, undefined, axiosInstance);
const adminApi = new AdminApi(config, undefined, axiosInstance);
const assetsApi = new AssetsApi(config, undefined, axiosInstance);
const paymentSettingsApi = new PaymentSettingsApi(config, undefined, axiosInstance);
const plansApi = new PlansApi(config, undefined, axiosInstance);
const chatSupportGroupApi = new ChatSupportGroupApi(config, undefined, axiosInstance);
const chatSupportMessageApi = new ChatSupportMessageApi(config, undefined, axiosInstance);
const racksApi = new RacksApi(config, undefined, axiosInstance);
const storageItemApi = new StorageItemApi(config, undefined, axiosInstance);
const storageRequestApi = new StorageRequestApi(config, undefined, axiosInstance);
const itemMessageGroupApi = new ItemMessageGroupApi(config, undefined, axiosInstance);
const itemInquiryMessageApi = new ItemInquiryMessageApi(config, undefined, axiosInstance);
const itemInternalMessageApi = new ItemInternalMessageApi(config, undefined, axiosInstance);
const storageItemNotesApi = new StorageItemNotesApi(config, undefined, axiosInstance);
const storageProcessApi = new StorageProcessApi(config, undefined, axiosInstance);
const shipToBuyerApi = new ShipToBuyerApi(config, undefined, axiosInstance);
const adminSettingApi = new AdminSettingApi(config, undefined, axiosInstance);
const shippoApi = new ShippoApiApi(config, undefined, axiosInstance);
const ebayReturnApi = new EbayReturnManagerApi(config, undefined, axiosInstance);
const ebayManagerApi = new EbayManagerApi(config, undefined, axiosInstance);
const exchangeRateApi = new ExchangeRateApi(config, undefined, axiosInstance);
const fileExportApi = new FileExportApi(config, undefined, axiosInstance);
const couponApi = new CouponApi(config, undefined, axiosInstance);
const userManagementApi = new UserManagementApi(config, undefined, axiosInstance);
const revenueApi = new RevenuesApi(config, undefined, axiosInstance);

export {
  adminApi,
  assetsApi,
  authApi,
  chatSupportGroupApi,
  chatSupportMessageApi,
  paymentSettingsApi,
  permissionApi,
  plansApi,
  racksApi,
  systemNotificationApi,
  usersApi,
  storageRequestApi,
  storageItemApi,
  itemMessageGroupApi,
  itemInquiryMessageApi,
  itemInternalMessageApi,
  storageItemNotesApi,
  storageProcessApi,
  shipToBuyerApi,
  adminSettingApi,
  shippoApi,
  ebayReturnApi,
  ebayManagerApi,
  exchangeRateApi,
  fileExportApi,
  couponApi,
  userManagementApi,
  revenueApi,
};
