import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import {
  INQUIRY_AND_INTERNAL_STATUS_BE,
  INQUIRY_AND_INTERNAL_STATUS_FE,
  STATUS_ITEM_MANAGEMENT,
  TIME_FORMAT,
} from './constants';
import { isBefore } from 'validator';
import { StorageItem } from '../apis/client-axios';
import { IntlShape } from 'react-intl';
// import moment from 'moment';

export const AmericaTimezone = 'America/Los_Angeles';

export const Helper = {
  getSourceFile: (source?: string) =>
    source
      ? process.env.REACT_APP_API_URL + '/assets/get-by-path/' + source
      : '/assets/icons/avatar/default-avatar.png',
};

export const DisabledFutureDate = (current: Dayjs | undefined) => {
  if (current) {
    const today = dayjs();
    return current && dayjs(current).isAfter(today, 'day');
  }
  return false;
};

export const DisabledAfterDay = (current: Dayjs | undefined) => {
  if (current) {
    const today: dayjs.Dayjs = dayjs();

    const _isAfter: boolean =
      today.date() <= 21 && +current.month() === +today.month() && +current.year() === +today.year();
    const _isBefore: boolean =
      today.date() > 21 &&
      (+today.month() < 11
        ? +current.month() === +today.month() + 1 && +current.year() === +today.year()
        : +current.month() === 0 && +current.year() === +today.year() + 1);
    const _isPast: boolean =
      /* isBefore(current.format(TIME_FORMAT.DATE('-')), today.toISOString()) */ +current.year() < +today.year() ||
      (current?.month() <= today.month() && +current.year() <= +today.year());

    return current && (_isAfter || _isBefore || _isPast);
  }
  return false;
};

export const formatDate = (time: Date | string) => (time ? moment(time).format('YYYY/MM/DD') : '');

// export const handleCopy = (text: string, textSuccess: string, textFailure: string) => {
//   const intl = useIntl();

//   navigator.clipboard
//     .writeText(text)
//     .then(() => {
//       NotificationSuccess({ content: intl.formatMessage({ id: textSuccess }) });
//     })
//     .catch((err) => {
//       NotificationError({ content: intl.formatMessage({ id: textFailure }) });
//     });
// };
export const handleViewStatusItem = (status: string, isMultiLang: boolean): string => {
  switch (status) {
    // storage request
    case STATUS_ITEM_MANAGEMENT.NOT_SHIPPED:
      return isMultiLang ? 'NOT_SHIPPED' : 'バイヤー発送前';
    case STATUS_ITEM_MANAGEMENT.IN_TRANSIT:
      return isMultiLang ? 'IN_TRANSIT' : '返送中';
    case STATUS_ITEM_MANAGEMENT.CANCELED:
      return isMultiLang ? 'CANCELED' : 'キャンセル済';
    case STATUS_ITEM_MANAGEMENT.DELIVERED:
      return isMultiLang ? 'DELIVERED' : '到着済';

    // Storage item
    case STATUS_ITEM_MANAGEMENT.NOT_DELIVERED:
      return isMultiLang ? 'NOT_DELIVERED' : '未配達';
    case STATUS_ITEM_MANAGEMENT.WAITING_STORAGE:
      return isMultiLang ? 'WAITING_STORAGE' : '保管情報登録待ち';
    case STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO:
      return isMultiLang ? 'WAITING_UPLOAD_IMAGE_VIDEO' : '画像・動画アップロード待ち';
    case STATUS_ITEM_MANAGEMENT.IN_STORAGE:
      return isMultiLang ? 'IN_STORAGE' : '保管中';
    case STATUS_ITEM_MANAGEMENT.SHIPPED_TO_BUYER:
      return isMultiLang ? 'SHIPPED_TO_BUYER' : '発送済';
    case STATUS_ITEM_MANAGEMENT.SHIPPED_BACK:
      // TODO: phase 2 shipback cần check lại
      return isMultiLang ? 'SHIPPED_BACK' : '発送';
    case STATUS_ITEM_MANAGEMENT.DISPOSED:
      return isMultiLang ? 'DISPOSED' : '廃棄済';

    // Request ship to buyer
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING:
      return isMultiLang ? 'SHIPPING_PREPARING' : '新規発送依頼';
    case STATUS_ITEM_MANAGEMENT.SHIPPING_PREPARING_DONE:
      return isMultiLang ? 'SHIPPING_PREPARING_DONE' : '発送準備完了';
    case STATUS_ITEM_MANAGEMENT.SHIPPED:
      return isMultiLang ? 'SHIPPED' : 'バイヤーへ発送済';

    // Request ship back
    case STATUS_ITEM_MANAGEMENT.NEW_DISPOSAL_REQUEST:
      return isMultiLang ? 'NEW_DISPOSAL_REQUEST' : '廃棄依頼';
    case STATUS_ITEM_MANAGEMENT.PRICE_INPUTED:
      return isMultiLang ? 'PRICE_INPUTED' : '廃棄処理待ち';

    // Request take a photo
    case STATUS_ITEM_MANAGEMENT.REQUESTED:
      return isMultiLang ? 'REQUESTED' : '画像撮影待ち';
    case STATUS_ITEM_MANAGEMENT.TAKE_BY_OPERATION:
      return isMultiLang ? 'TAKE_BY_OPERATION' : '画像アップロード待ち';
    case STATUS_ITEM_MANAGEMENT.UPLOAD_BY_STAFF:
      return isMultiLang ? 'UPLOAD_BY_STAFF' : '画像アップロード済';
    default:
      return '';
  }
};

export const renderStatus = (itemDetail: StorageItem): string => {
  let currentStatus: string = '';
  if (itemDetail?.storageRequest?.status !== STATUS_ITEM_MANAGEMENT.DELIVERED) {
    currentStatus = itemDetail?.storageRequest?.status;
  } else if (
    itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
    itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
    itemDetail?.disposalRequest?.status
  ) {
    currentStatus =
      itemDetail?.shipToBuyerItem?.shipToBuyerRequest?.status ||
      itemDetail?.shipBackSellerItem?.shipBackSellerRequest?.status ||
      itemDetail?.disposalRequest?.status;
  } else {
    currentStatus = itemDetail?.status;
  }
  return currentStatus;
};

export const renderStatusImage = (status: string): string => {
  switch (status) {
    case STATUS_ITEM_MANAGEMENT.REQUESTED:
      return 'REQUESTED';
    case STATUS_ITEM_MANAGEMENT.TAKE_BY_OPERATION:
      return 'TAKE_BY_OPERATION';
    case STATUS_ITEM_MANAGEMENT.UPLOAD_BY_STAFF:
      return 'UPLOAD_BY_STAFF';
    default:
      return '';
  }
};

type TDataInternal = {
  status: string | null;
  isRead: number;
  intlMultiLang: IntlShape;
  authUserId?: number;
  sendBy?: number;
};
export const handleShowInquiryDiscussStatus = (data: TDataInternal) => {
  const { status, isRead, intlMultiLang } = data;
  let statusCheck: string = status;
  if (status == INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED && isRead == 0) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED_UNREAD;
  } else if (status == INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED && isRead == 1) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED_READED;
  } else if (status == INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE && isRead == 0) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.NULL;
  } else if (status === null && isRead == 0) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.NULL;
  } else if (status === null && isRead === 1) {
    statusCheck = '';
  }
  switch (statusCheck) {
    case INQUIRY_AND_INTERNAL_STATUS_FE.OPEN:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.OPEN' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED_UNREAD:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.CLOSED_UNREAD' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED_READED:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.CLOSED_READED' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.NO_ACTION_OKE:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.NO_ACTION_OKE' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.NULL:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.NULL' });
    default:
      return '';
  }
};
// NEW_MESSAGE ( by admin ) | NO_ACTION_OK | OPEN | CLOSED -> 1 case <-> read + unread
export const handleShowInternalDiscussStatus = (data: TDataInternal) => {
  const { status, isRead, intlMultiLang, authUserId, sendBy } = data;
  let statusCheck: string = status;
  if (authUserId === sendBy && status === INQUIRY_AND_INTERNAL_STATUS_FE.NEW_MESSAGE) {
    statusCheck = '';
  } else if (authUserId !== sendBy && status === INQUIRY_AND_INTERNAL_STATUS_FE.NEW_MESSAGE) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.NULL;
  } else if (status == INQUIRY_AND_INTERNAL_STATUS_BE.NO_ACTION_OKE) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.NO_ACTION_OKE;
  } else if (status == INQUIRY_AND_INTERNAL_STATUS_BE.OPEN) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.OPEN;
  } else if (status == INQUIRY_AND_INTERNAL_STATUS_BE.CLOSED) {
    statusCheck = INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED;
  } else {
    statusCheck = '';
  }
  switch (statusCheck) {
    case INQUIRY_AND_INTERNAL_STATUS_FE.OPEN:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.OPEN' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.NO_ACTION_OKE:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.NO_ACTION_OKE' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.NULL:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.NULL' });
    case INQUIRY_AND_INTERNAL_STATUS_FE.CLOSED:
      return intlMultiLang.formatMessage({ id: 'request.INQUIRY_AND_INTERNAL_STATUS.CLOSED_READED' });
    default:
      return '';
  }
};
